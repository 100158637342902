.arrow-left {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid gray;
}

a#twitter-login-link {
    background: url('twitter-login.png');
}

a.sbf-login-link, a#sbf-authcodecreate-login-link {
    background-color: #6CA044;
}

a.sbf-login-link:hover, a#sbf-authcodecreate-login-link:hover {
    background-color: #609438;
}

a,
a.cancel {
    text-decoration: underline;
}

a#sbf-login-forgot-username-link, a#sbf-login-forgot-password-link,
a#sbf-login-associate-forgot-username, a#sbf-login-associate-forgot-password {
    color: #7a9d4e;
    font-size: 12px;
    border-bottom: none;
}

div.content {
    overflow: visible;
}

#sbf-login-forgot-username-email-address-error {
    width: 140%;
    margin-left: -13%;
}

.alert.popover {
    z-index: 5;
}

h3.title {
    margin-bottom: 16px;
    margin-bottom: 1rem;
}

h3.title + p {
    font-size: 18px;
}

@media (max-width: 680px) {
    #sbf-login-forgot-username-email-address-error {
        width: 80%;
        margin-left: 13%;
    }
}
